/*
* De PX para EM
* .calc-em(16)
* Retorna: 1em
* ou use @return para pegar apenas o valor
*/
/**
* De PX para REM
* .calc-rem(16)
* Retorna: 1rem
* ou use @return para pegar apenas o valor
*/
/*
* De REM ou EM para PX
* .calc-px(1)
* Retorna: 16px
* ou use @return para pegar apenas o valor
*/
/*
* De PX para EM
* .calc-rem(16px)
* Retorna: 1rem
*/
/**
* De PX para REM
* .calc-rem(16px)
* Retorna: 1rem
*/
/*
 * De REM ou EM para PX
 * .calc-rem(1em)
 * Retorna: 16px
 * ou use @return para pegar apenas o valor
 */
/*
 * Pontos de quebra do CSS
 * usar .calc-em(@mobile) para receber o valor formatado em EM
 */
/**
 * Tabelas de cores
*/
/*
 * Arquivo que contém os estilos dos elementos do header
 * Desenvolvido por Lucas Oliveira
 * Data 18/09/2018
 */
/* HEADER */
.header-top {
  background-color: #FFF;
  height: auto;
  max-height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all ease 2s;
  border-bottom: 1px solid #D8D8D8;
  /*
  .header.header--fixed {
    background: #fff;
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all ease 2s;
  }
  */
}
@media (min-width: 64em) {
  .header-top {
    border-bottom: none;
  }
}
.header-top .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.875rem 0;
}
@media (min-width: 64em) {
  .header-top .container {
    align-items: center;
  }
}
@media (min-width: 64em) {
  .header-top .container {
    max-width: 71.25rem;
  }
}
.header-top .header-logo {
  max-width: 150px;
  width: 100%;
}
.header-top .header-logo a {
  font-size: 0;
}
@media (min-width: 32em) {
  .header-top .header-logo {
    max-width: 200px;
  }
}
@media (min-width: 64em) {
  .header-top .header-logo {
    max-width: 220px;
  }
}
.header-top .header-actions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 30px;
  width: 100%;
  height: 26px;
  margin-right: 26px;
}
@media (min-width: 64em) {
  .header-top .header-actions {
    align-items: center;
    max-width: initial;
    width: auto;
  }
}
.header-top .header-actions button {
  color: #043156;
}
.header-top .header-actions .btn-icon {
  background: none;
  font-size: 1.8rem;
  position: relative;
  text-decoration: none;
  border: none;
  display: flex;
}
@media (min-width: 64em) {
  .header-top .header-actions .btn-icon {
    font-size: 2.375rem;
    height: 30px;
  }
}
.header-top .header-actions span {
  display: block;
  font-size: 0;
}
.header-top .header-actions .header-actions-company-name {
  align-items: center;
  display: flex;
  justify-items: center;
  flex-direction: column;
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}
.header-top .header-actions .header-actions-company-name span {
  font-size: 0.75rem;
  text-transform: initial;
}
.header-top .header-actions .header-actions-search {
  background-color: inherit;
  border: 0;
  display: none;
  font-size: 1.875rem;
  padding-top: 0.8125rem;
  margin-right: 1.25rem;
}
@media (min-width: 48em) {
  .header-top .header-actions .header-actions-search {
    display: inline-block;
  }
}
.header-top .header-hide {
  display: none;
}
/*
 * Menu em linha (Desktop)
 */
.menu-desk {
  display: none;
  width: 100%;
  max-width: 700px;
}
@media (min-width: 64em) {
  .menu-desk {
    display: flex;
  }
}
@media (min-width: 75em) {
  .menu-desk {
    max-width: 800px;
  }
}
.menu-desk .header-navigation {
  display: flex;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  text-transform: uppercase;
}
@media (min-width: 64em) {
  .menu-desk .header-navigation {
    display: flex;
    background: initial;
    display: inline-block;
    height: auto;
    position: initial;
    width: 100%;
    transform: none;
  }
}
.menu-desk .header-navigation--active {
  transform: scale(1);
}
.menu-desk .header-navigation-content {
  background: #fff;
  border: 1px solid #98C1D9;
  margin: auto;
  max-width: 300px;
  padding: 0.9rem;
  position: relative;
  width: 100%;
  border-radius: 10px;
}
@media (min-width: 64em) {
  .menu-desk .header-navigation-content {
    background: transparent;
  }
}
.menu-desk .header-navigation .btn-close {
  color: #98C1D9;
  font-size: 1.3rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
.menu-desk .header-navigation ul .sub-menu {
  display: none;
}
.menu-desk .header-navigation ul li {
  display: inline-flex;
  justify-content: space-between;
  text-decoration: none;
}
.menu-desk .header-navigation ul li a {
  display: block;
  padding-right: 1rem;
  text-decoration: none;
  color: #043156;
  font-size: 0.875rem;
  /*font-weight: 500;*/
  line-height: 1.0625rem;
}
/*
 * Menu Popup (Mobile/Desktop)
 */
.header-mobile {
  display: inline-block;
  background-color: #043156;
  width: 95%;
  max-width: 360px;
  float: right;
  z-index: 25;
  position: fixed;
  top: 0;
  right: -1000px;
  transition: right 1s linear;
  height: 100%;
  text-transform: uppercase;
}
@media (min-width: 48em) {
  .header-mobile {
    height: 35.3125rem;
  }
}
.header-mobile.j_header-open--active {
  right: 0;
}
@media (min-width: 32em) {
  .header-mobile {
    box-shadow: -11px 0 15px 0 rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 360px;
  }
}
.header-mobile a {
  text-decoration: none;
  color: #FFF;
}
.header-mobile section {
  width: 100%;
  display: inline-block;
}
.header-mobile header {
  display: inline-block;
  width: 100%;
}
.header-mobile header .icon-act-close {
  color: #FFF;
  font-size: 18px;
  font-weight: normal;
  /* position: absolute; */
  top: 0.8rem;
  margin: 10px;
  background: transparent;
  border: 0;
  float: right;
}
.header-mobile header .menu-logo-mobile {
  width: 100%;
  clear: both;
  float: left;
  text-align: center;
}
.header-mobile header .menu-logo-mobile a {
  font-size: 0;
}
.header-mobile header .menu-logo-mobile img {
  width: 100%;
  max-width: 54px;
}
.header-mobile .menu-mobile {
  width: 100%;
  max-width: 230px;
  margin: 20px auto;
}
.header-mobile .menu-mobile ul .sub-menu {
  display: none;
  /*font-weight: 500;*/
  padding: 10px 0 10px 20px;
}
.header-mobile .menu-mobile ul .sub-menu li {
  padding: 0;
  padding-left: 12px;
  border-left: 3px solid #064d87;
}
.header-mobile .menu-mobile ul .sub-menu a {
  font-size: 0.6875rem;
  line-height: 1.375rem;
}
.header-mobile .menu-mobile ul li {
  display: inline-block;
  padding: 12px 0;
  width: 100%;
}
.header-mobile .menu-mobile a {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.125rem;
  /*font-weight: 500;*/
  text-decoration: none;
}
.header-mobile .menu-mobile .icon-toggle-menu {
  display: inline-block;
  float: right;
  color: #FFF;
}
.header-mobile footer {
  background-color: #043873;
  display: inline-block;
  width: 100%;
  padding: 34px 0;
  position: absolute;
  bottom: 0;
}
.header-mobile footer ul {
  text-align: center;
}
.header-mobile footer ul li {
  display: inline-block;
  padding: 0 10px;
}
.header-mobile footer a::before {
  font-size: 1.5625rem;
}
.header-mobile--active {
  transform: scale(1);
}
.icon-notext--deg:before {
  display: inline-block;
  transform: rotate(-93deg);
}
.search-potenciar {
  margin-bottom: 1.25rem;
}
.search-potenciar .search-potenciar-inputs-text {
  background-color: #043156;
  border: 0;
  border-radius: 0;
  border-bottom: 0.0625rem solid #FFF;
  color: #FFF;
  display: inline-block;
}
.search-potenciar .search-potenciar-inputs-submit {
  background-color: #043156;
  border: 0;
  color: #FFF;
  display: inline-block;
  font-size: 1.5rem;
  position: absolute;
  right: 4.375rem;
}
