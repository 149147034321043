/*
* De PX para EM
* .calc-em(16)
* Retorna: 1em
* ou use @return para pegar apenas o valor
*/
/**
* De PX para REM
* .calc-rem(16)
* Retorna: 1rem
* ou use @return para pegar apenas o valor
*/
/*
* De REM ou EM para PX
* .calc-px(1)
* Retorna: 16px
* ou use @return para pegar apenas o valor
*/
/*
* De PX para EM
* .calc-rem(16px)
* Retorna: 1rem
*/
/**
* De PX para REM
* .calc-rem(16px)
* Retorna: 1rem
*/
/*
 * De REM ou EM para PX
 * .calc-rem(1em)
 * Retorna: 16px
 * ou use @return para pegar apenas o valor
 */
/*
 * Pontos de quebra do CSS
 * usar .calc-em(@mobile) para receber o valor formatado em EM
 */
/**
 * Tabelas de cores
*/
/*
 * Arquivo que contém os estilos dos elementos do footer
 * Desenvolvido por Lucas Oliveira
 * Data 20/09/2018
 */
.newsletter {
  background-color: #043156;
  margin-top: 3.875rem !important;
}
@media (min-width: 48em) {
  .newsletter {
    margin-top: 5.375rem !important;
  }
}
.newsletter .emaillist {
  color: #FFF;
  text-align: center;
}
.newsletter .newsletter-grid {
  margin: 3.125rem auto;
  padding: 0 1.25rem;
}
@media (min-width: 48em) {
  .newsletter .newsletter-grid {
    margin: 1.875rem auto 0.625rem;
  }
}
.newsletter .newsletter-grid .es-field-wrap {
  position: relative;
  margin: auto;
  width: 100%;
}
@media (min-width: 48em) {
  .newsletter .newsletter-grid .es-field-wrap:first-child {
    margin-right: 2.5rem;
  }
}
@media (min-width: 48em) {
  .newsletter .newsletter-grid .es-field-wrap {
    display: inline-block;
    width: 21.3125rem;
  }
}
@media (min-width: 64em) {
  .newsletter .newsletter-grid .es-field-wrap {
    width: 22.3125rem;
  }
}
.newsletter .newsletter-grid .es-field-wrap label {
  color: #FFF;
  font-size: 0.875rem;
  position: absolute;
  top: 0.9375rem;
}
.newsletter .newsletter-grid .es-field-wrap input {
  background-color: #043156;
  border-radius: inherit;
  border: 0;
  border-bottom: 0.125rem solid #FFF;
  color: #FFF;
  padding-left: 3.4375rem;
  padding-top: 0.9375rem;
}
.newsletter .newsletter-grid .es-field-wrap input:-internal-autofill-selected {
  background-color: #043156 !important;
  background-image: none !important;
  color: #FFF !important;
}
.newsletter .newsletter-title {
  color: #FFF;
  font-size: 1.8125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  text-align: center;
}
.newsletter .newsletter-subtitle {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 0.625rem;
  text-align: center;
}
.newsletter .newsletter-polite a {
  color: #AFAFAF;
  display: block;
  font-size: 0.625rem;
  margin-top: 0.75rem;
  text-align: center;
}
.newsletter .es_subscription_form_submit.es_submit_button.es_textbox_button {
  border: 0.25rem solid #FFF;
  background-color: #043156;
  border-radius: 0;
  color: #FFF;
  font-size: 1.5625rem;
  display: block;
  height: 3.5rem;
  margin: 3.75rem auto 0;
  width: 11rem;
}
@media (min-width: 48em) {
  .newsletter .es_subscription_form_submit.es_submit_button.es_textbox_button {
    margin: 1.5625rem auto 0;
  }
}
.footer {
  background: #FFF;
  padding: 1.625rem;
  width: 100%;
  align-items: center;
  display: flex;
}
@media (min-width: 64em) {
  .footer-box-address {
    width: 59%;
  }
  .footer-box-contact {
    width: 40%;
  }
}
@media (min-width: 75em) {
  .footer-box-contact {
    padding-top: 0rem;
  }
}
.footer h2,
.footer a,
.footer span {
  display: inline-block;
  text-decoration: none;
  color: #454545;
  font-size: 1rem;
  line-height: 2.75rem;
}
.footer-box-item {
  margin: 0;
}
@media (min-width: 32em) {
  .footer-box-item {
    float: left;
  }
}
.footer-box-item a {
  display: inline-flex;
  align-items: center;
}
.footer-box-item span {
  line-height: 1rem;
  padding: 0.375rem 0;
}
@media (min-width: 32em) {
  .footer-box-item span {
    padding: 0;
  }
}
.footer-box-item span::before {
  color: #043156;
  font-size: 1.5625rem;
  padding-right: 0.3125rem;
}
.footer-address {
  align-items: center;
  display: flex;
}
@media (min-width: 64em) {
  .footer-address {
    align-items: flex-end;
  }
}
.footer-address h2 {
  line-height: 1.1875rem;
  max-width: 85%;
  padding-left: 0.625rem;
  text-align: justify;
  font-weight: 300;
}
.footer-contact {
  font-weight: 300;
}
@media (min-width: 32em) {
  .footer-contact {
    float: right;
  }
}
.footer-contact .contact-item {
  display: inline-flex;
  align-items: center;
  float: left;
  font-size: 1.125rem;
}
@media (min-width: 32em) {
  .footer-contact .contact-item {
    margin-bottom: 0.875rem;
  }
}
.footer-contact a {
  padding: 0 0.1875rem;
}
@media (min-width: 64em) {
  .footer-contact span {
    font-size: 1.125rem;
  }
}
.footer-social {
  text-align: center;
  margin: 0;
}
@media (min-width: 32em) {
  .footer-social {
    text-align: right;
  }
}
.footer-social a {
  font-size: 0;
  line-height: 2rem;
}
.footer-social a::before {
  color: #043156;
  font-size: 2rem;
}
.footer .copy {
  font-weight: 300;
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
}
.footer .copy a {
  font-size: 0.75rem;
}
.contact-float {
  bottom: 6.25rem;
  position: fixed;
  right: 1.375rem;
  z-index: 9999;
}
.contact-float .icone {
  align-items: center;
  color: #FFF;
  border: 0;
  font-size: 2.1875rem;
  border-radius: 100%;
  display: flex;
  height: 3.3125rem;
  justify-content: center;
  margin: 0.3125rem auto;
  width: 3.3125rem;
}
.contact-float .contact-float-group {
  display: none;
}
.contact-float .contact-float-group-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-float .contact-float-group-icon .contact-float-social-whatsapp {
  align-items: center;
  color: #FFF;
  border: 0;
  font-size: 2.1875rem;
  border-radius: 100%;
  display: flex;
  height: 3.3125rem;
  justify-content: center;
  margin: 0.3125rem auto;
  width: 3.3125rem;
  background-color: #67bc4d;
}
.contact-float .contact-float-group-icon .contact-float-social-email {
  align-items: center;
  color: #FFF;
  border: 0;
  font-size: 2.1875rem;
  border-radius: 100%;
  display: flex;
  height: 3.3125rem;
  justify-content: center;
  margin: 0.3125rem auto;
  width: 3.3125rem;
  background-color: #F0C732;
}
.contact-float .contact-float-group-icon .contact-float-social-phone {
  align-items: center;
  color: #FFF;
  border: 0;
  font-size: 2.1875rem;
  border-radius: 100%;
  display: flex;
  height: 3.3125rem;
  justify-content: center;
  margin: 0.3125rem auto;
  width: 3.3125rem;
  background-color: #7C529C;
}
@media (min-width: 48em) {
  .contact-float .contact-float-group-icon .contact-float-social-phone {
    display: none;
  }
}
.contact-float .contact-float-social-chat {
  align-items: center;
  color: #FFF;
  border: 0;
  font-size: 2.1875rem;
  border-radius: 100%;
  display: flex;
  height: 3.3125rem;
  justify-content: center;
  margin: 0.3125rem auto;
  width: 3.3125rem;
  background-color: #002655;
}
