@charset "UTF-8";
/*
 * Arquivo que contém todos os estilos padrões da loja
 * Desenvolvido por Lucas Oliveira
 * Data 18/09/2018
 */
/* Imports */
/*
* De PX para EM
* .calc-em(16)
* Retorna: 1em
* ou use @return para pegar apenas o valor
*/
/**
* De PX para REM
* .calc-rem(16)
* Retorna: 1rem
* ou use @return para pegar apenas o valor
*/
/*
* De REM ou EM para PX
* .calc-px(1)
* Retorna: 16px
* ou use @return para pegar apenas o valor
*/
/*
* De PX para EM
* .calc-rem(16px)
* Retorna: 1rem
*/
/**
* De PX para REM
* .calc-rem(16px)
* Retorna: 1rem
*/
/*
 * De REM ou EM para PX
 * .calc-rem(1em)
 * Retorna: 16px
 * ou use @return para pegar apenas o valor
 */
/*
 * Pontos de quebra do CSS
 * usar .calc-em(@mobile) para receber o valor formatado em EM
 */
/**
 * Tabelas de cores
*/
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../fonts/web/hinted-AvenirLTStd-Light.eot');
  src: local('Avenir LT Std 35 Light'), local('AvenirLTStd-Light'), url('../fonts/web/hinted-AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/web/hinted-AvenirLTStd-Light.woff2') format('woff2'), url('../fonts/web/hinted-AvenirLTStd-Light.woff') format('woff'), url('../fonts/web/hinted-AvenirLTStd-Light.ttf') format('truetype'), url('../fonts/web/hinted-AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../fonts/web/hinted-AvenirLTStd-Black.eot');
  src: local('Avenir LT Std 95 Black'), local('AvenirLTStd-Black'), url('../fonts/web/hinted-AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/web/hinted-AvenirLTStd-Black.woff2') format('woff2'), url('../fonts/web/hinted-AvenirLTStd-Black.woff') format('woff'), url('../fonts/web/hinted-AvenirLTStd-Black.ttf') format('truetype'), url('../fonts/web/hinted-AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../fonts/web/hinted-AvenirNextLTPro-Regular.eot');
  src: local('Avenir Next LT Pro Regular'), local('AvenirNextLTPro-Regular'), url('../fonts/web/hinted-AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/web/hinted-AvenirNextLTPro-Regular.woff2') format('woff2'), url('../fonts/web/hinted-AvenirNextLTPro-Regular.woff') format('woff'), url('../fonts/web/hinted-AvenirNextLTPro-Regular.ttf') format('truetype'), url('../fonts/web/hinted-AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../fonts/web/hinted-AvenirNextLTPro-Bold.eot');
  src: local('Avenir Next LT Pro Bold'), local('AvenirNextLTPro-Bold'), url('../fonts/web/hinted-AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/web/hinted-AvenirNextLTPro-Bold.woff2') format('woff2'), url('../fonts/web/hinted-AvenirNextLTPro-Bold.woff') format('woff'), url('../fonts/web/hinted-AvenirNextLTPro-Bold.ttf') format('truetype'), url('../fonts/web/hinted-AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../fonts/web/hinted-AvenirNextLTPro-Demi.eot');
  src: local('Avenir Next LT Pro Demi'), local('AvenirNextLTPro-Demi'), url('../fonts/web/hinted-AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'), url('../fonts/web/hinted-AvenirNextLTPro-Demi.woff2') format('woff2'), url('../fonts/web/hinted-AvenirNextLTPro-Demi.woff') format('woff'), url('../fonts/web/hinted-AvenirNextLTPro-Demi.ttf') format('truetype'), url('../fonts/web/hinted-AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../fonts/web/hinted-AvenirNextLTPro-It.eot');
  src: local('Avenir Next LT Pro Italic'), local('AvenirNextLTPro-It'), url('../fonts/web/hinted-AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'), url('../fonts/web/hinted-AvenirNextLTPro-It.woff2') format('woff2'), url('../fonts/web/hinted-AvenirNextLTPro-It.woff') format('woff'), url('../fonts/web/hinted-AvenirNextLTPro-It.ttf') format('truetype'), url('../fonts/web/hinted-AvenirNextLTPro-It.svg#AvenirNextLTPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
}
/*
 * Arquivo que contém is icons (font) utilizados no template
 * Desenvolvido por Miguel Soares
 * Data 09/01/2019
 * Criado com http://app.fontastic.me/#publish/font/iouqUgR9HeP5U9QawUxj2k
 */
@font-face {
  font-family: "potenciar-icons";
  src: url("../fonts/icons/potenciar-icons.eot");
  src: url("../fonts/icons/potenciar-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/icons/potenciar-icons.woff") format("woff"), url("../fonts/icons/potenciar-icons.ttf") format("truetype"), url("../fonts/icons/potenciar-icons.svg#potenciar-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "potenciar-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "potenciar-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* */
.icon-notext:before {
  top: 0;
  margin-right: 0 !important;
}
/* */
.icon-hidden-text {
  font-size: 0px !important;
}
.icon-social-linkedin-alt:before {
  content: "\61";
}
.icon-social-whatsapp:before {
  content: "\62";
}
.icon-social-facebook-alt:before {
  content: "\66";
}
.icon-social-email:before {
  content: "\63";
}
.icon-act-up:before {
  content: "\64";
}
.icon-act-right:before {
  content: "\67";
}
.icon-act-left:before {
  content: "\68";
}
.icon-act-down:before {
  content: "\69";
}
.icon-act-search-alt:before {
  content: "\6a";
}
.icon-act-close:before {
  content: "\6b";
}
.icon-phone:before {
  content: "\6c";
}
.icon-pag-caledario:before {
  content: "\6e";
}
.icon-social-chat:before {
  content: "\6f";
}
.icon-pag-comprovada-cientificamente:before {
  content: "\70";
}
.icon-pag-consultores-especialistas:before {
  content: "\71";
}
.icon-pag-metodologia:before {
  content: "\72";
}
.icon-social-facebook:before {
  content: "\74";
}
.icon-social-linkedin:before {
  content: "\75";
}
.icon-act-menu:before {
  content: "\76";
}
.icon-pag-metodologia-pantedeada:before {
  content: "\77";
}
.icon-act-pesquisa-ponteciar:before {
  content: "\78";
}
.icon-pag-tempo:before {
  content: "\79";
}
.icon-pag-turma:before {
  content: "\7a";
}
.icon-act-seta:before {
  content: "\41";
}
.clear {
  clear: both;
}
/*DISPLAY MODE*/
.ds-none {
  display: none !important;
}
.ds-block {
  display: block;
}
.ds-inline {
  display: inline;
}
.ds-inblock {
  display: inline-block;
}
/*OPACITY*/
.opacity-zero {
  opacity: 0;
}
.opacity-one {
  opacity: 1;
}
/*MARGIN MODE*/
.m-top {
  margin-top: 20px;
}
.m-bottom {
  margin-bottom: 20px !important;
}
.m-left {
  margin-left: 20px;
}
.m-right {
  margin-right: 20px;
}
.m-nomargin {
  margin: 0px !important;
}
.m-margin {
  margin: 0 0 20px 0 !important;
}
/*ALIGN*/
.al-center {
  text-align: center !important;
}
.al-left {
  text-align: left !important;
}
.al-right {
  text-align: right;
}
.al-justify {
  text-align: justify;
}
/*VISIBLED*/
.on-mobile {
  display: block;
}
.on-desktop {
  display: none;
}
.radius {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.rounded {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
/***********************************
############ BOX MODEL #############
***********************************/
.box {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.box::before {
  clear: both;
}
.box1 {
  width: 100%;
  margin: 15px 0 15px 0;
}
.box2 {
  width: 49%;
  margin: 1% 2% 1% 0;
}
.box2:nth-of-type(2n+0) {
  margin-right: 0;
}
.box3 {
  width: 32%;
  margin: 1% 2% 1% 0;
}
.box3:nth-of-type(3n+0) {
  margin-right: 0;
}
.box4 {
  width: 23.5%;
  margin: 1% 2% 1% 0;
}
.box4:nth-of-type(4n+0) {
  margin-right: 0;
}
.box5 {
  width: 18.4%;
  margin: 1% 2% 1% 0;
}
.box5:nth-of-type(5n+0) {
  margin-right: 0;
}
.box_bar,
.box_side {
  width: 100%;
  padding: 0;
  position: relative;
}
/***********************************
############# TRIGGER ##############
***********************************/
.trigger {
  background: #888;
  border: 1px solid #666;
  color: #fff;
  font-size: 0.875em;
  margin-top: 12px;
  padding: 12px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.trigger-success {
  background: #1b998b;
  border-color: #188d7f;
}
.trigger-success-out {
  color: #1b998b;
  border-color: #1b998b;
  background: none;
  text-shadow: none;
}
.trigger-info {
  background: #4fc0e8;
  border-color: v #3aadd9;
}
.trigger-info-out {
  color: #4fc0e8;
  border-color: #4fc0e8;
  background: none;
  text-shadow: none;
}
.trigger-alert {
  background: #fdcd56;
  border-color: #f5b945;
}
.trigger-alert-out {
  color: #fdcd56;
  border-color: #fdcd56;
  background: none;
  text-shadow: none;
}
.trigger-error {
  background: #eb5463;
  border-color: #d94452;
}
.trigger-error-out {
  color: #eb5463;
  border-color: #eb5463;
  background: none;
  text-shadow: none;
}
/***********************************
########## MEDIA QUERIES ###########
***********************************/
/* max 544px */
@media (max-width: 34em) {
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 100%;
    margin: 0 0 5% 0 !important;
  }
}
@media (min-width: 48em) {
  .box_side {
    width: 69%;
    padding-right: 40px;
  }
  .box_bar {
    width: 30%;
  }
}
@media (min-width: 34em) and (max-width: 48em) {
  .box2,
  .box3,
  .box4,
  .box5 {
    width: 49%;
    margin: 1% 2% 1% 0 !important;
  }
  .box2:nth-of-type(2n+0),
  .box3:nth-of-type(2n+0),
  .box4:nth-of-type(2n+0),
  .box5:nth-of-type(2n+0) {
    margin-right: 0 !important;
  }
}
@media (min-width: 48em) and (max-width: 64em) {
  .box3,
  .box4,
  .box5 {
    width: 32%;
    margin: 1% 2% 1% 0 !important;
  }
  .box3:nth-of-type(3n+0),
  .box4:nth-of-type(3n+0),
  .box5:nth-of-type(3n+0) {
    margin-right: 0 !important;
  }
}
@media (min-width: 64em) {
  .on-mobile {
    display: none !important;
  }
  .on-desktop {
    display: block;
  }
}
@media (min-width: 64em) and (max-width: 80em) {
  .box4,
  .box5 {
    width: 23.5%;
    margin: 1% 2% 1% 0 !important;
  }
  .box4:nth-of-type(4n+0),
  .box5:nth-of-type(4n+0) {
    margin-right: 0 !important;
  }
}
