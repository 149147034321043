/*
* De PX para EM
* .calc-em(16)
* Retorna: 1em
* ou use @return para pegar apenas o valor
*/
/**
* De PX para REM
* .calc-rem(16)
* Retorna: 1rem
* ou use @return para pegar apenas o valor
*/
/*
* De REM ou EM para PX
* .calc-px(1)
* Retorna: 16px
* ou use @return para pegar apenas o valor
*/
/*
* De PX para EM
* .calc-rem(16px)
* Retorna: 1rem
*/
/**
* De PX para REM
* .calc-rem(16px)
* Retorna: 1rem
*/
/*
 * De REM ou EM para PX
 * .calc-rem(1em)
 * Retorna: 16px
 * ou use @return para pegar apenas o valor
 */
/*
 * Pontos de quebra do CSS
 * usar .calc-em(@mobile) para receber o valor formatado em EM
 */
/**
 * Tabelas de cores
*/
/*
 * Desenvolvido por Lucas Oliveira
 */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
  -moz-appearance: textfield;
}
input.error,
textarea.error,
select.error {
  border: 1px solid #eb5463;
}
img {
  max-width: 100%;
  vertical-align: middle;
  margin: 0;
}
a img {
  border: none;
  margin: 0;
}
ul {
  list-style: none;
}
table,
tr,
td,
th {
  border: 0px;
}
.site-title {
  top: -99px;
  left: -99px;
  position: absolute;
  text-indent: -9999px;
}
fieldset {
  border: 0;
}
input,
select,
textarea {
  border: none;
  padding: 10px;
  outline: none;
  width: 100%;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
input.btn,
button {
  cursor: pointer;
  outline: none;
  width: auto;
}
.container,
.page-header,
.containerPage,
.row {
  display: block;
  margin: 0 auto !important;
}
