/*
* De PX para EM
* .calc-em(16)
* Retorna: 1em
* ou use @return para pegar apenas o valor
*/
/**
* De PX para REM
* .calc-rem(16)
* Retorna: 1rem
* ou use @return para pegar apenas o valor
*/
/*
* De REM ou EM para PX
* .calc-px(1)
* Retorna: 16px
* ou use @return para pegar apenas o valor
*/
/*
* De PX para EM
* .calc-rem(16px)
* Retorna: 1rem
*/
/**
* De PX para REM
* .calc-rem(16px)
* Retorna: 1rem
*/
/*
 * De REM ou EM para PX
 * .calc-rem(1em)
 * Retorna: 16px
 * ou use @return para pegar apenas o valor
 */
/*
 * Pontos de quebra do CSS
 * usar .calc-em(@mobile) para receber o valor formatado em EM
 */
/**
 * Tabelas de cores
*/
/*
 * Arquivo que contém todos os estilos padrões da loja
 * Desenvolvido por Lucas Oliveira
 * Data 18/09/2018
 */
* {
  font-family: 'Avenir Next LT Pro';
  /*font-weight: normal !important;*/
  font-style: normal;
  font-variant: normal;
}
body {
  overflow-x: hidden;
}
.parts {
  width: 100%;
  clear: both;
  float: left;
  margin: 1.875rem auto;
}
.parts .box1 {
  margin: 0;
}
.parts .content {
  max-width: 100%;
  width: 100%;
  margin: auto;
  display: block;
  font-size: 1.125rem;
}
@media (min-width: 64em) {
  .parts .content {
    max-width: 71.25rem;
  }
}
@media (min-width: 64em) {
  .parts .large {
    max-width: 100rem;
  }
}
.parts .header {
  margin: 0 0 2rem 0;
  position: relative;
}
.parts .header h1,
.parts .header h2 {
  color: #043156;
  margin: 0;
}
.parts .header h1 {
  font-size: 2.5rem;
  line-height: 3.125rem;
  text-align: center;
  font-weight: normal;
}
@media (min-width: 48em) {
  .parts .header h1 {
    font-size: 3.5rem;
    line-height: 3.75rem;
  }
}
@media (min-width: 64em) {
  .parts .header h1 {
    font-size: 4rem;
    line-height: 4.375rem;
  }
}
.parts .header h2 {
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 300;
  text-align: center;
}
@media (min-width: 48em) {
  .parts .header h2 {
    font-size: 3rem;
    line-height: 3.625rem;
  }
}
.parts a {
  text-decoration: none;
}
.container {
  width: 100%;
  max-width: 95%;
}
@media (min-width: 64em) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 75em) {
  .container {
    max-width: 71.25rem;
  }
}
@media (min-width: 112.5em) {
  .container {
    max-width: 100rem;
  }
}
/* BREADCRUMB */
.bread-crumb {
  display: block;
  text-align: left;
  width: 100%;
  position: relative;
}
.bread-crumb ul {
  max-width: 1240px;
  margin: 0.625rem auto;
  width: 95%;
}
.bread-crumb ul li:first-child::before {
  content: '';
}
.bread-crumb ul li {
  display: inline-block;
  font-size: 0.75rem;
}
.bread-crumb ul li::before {
  content: '>';
  color: #171717;
  display: inline-block;
  font-size: 0.75rem;
}
.bread-crumb ul li a {
  color: #171717;
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}
.bread-crumb ul .last {
  margin-right: 0.2rem;
}
/* Media 1024 */
@media (min-width: 64em) {
  /* BANNER FEATURED */
  .banner-featuded {
    padding: 2.5rem 0;
  }
  /* BREADCRUMB */
}
@media (max-width: 768px) {
  /*BREADCRUMB*/
  .bread-crumb {
    float: left;
    padding: 0.5em 0;
  }
  .bread-crumb ul {
    width: 90%;
  }
  .bread-crumb ul li::after {
    font-size: 1em;
    line-height: 0;
  }
}
.article-ticket {
  background-color: #004181;
  color: #FFF;
  display: block;
  font-size: 1.125rem;
  height: 24px;
  position: absolute;
  right: 20px;
  line-height: 1.5rem;
  top: 15px;
  text-align: center;
  width: 73px;
}
.grid {
  display: block;
  max-width: 50rem !important;
  margin: auto;
}
.breadcrumb {
  display: block;
  text-align: left;
  width: 100%;
  position: relative;
}
.breadcrumb p {
  max-width: 1240px;
  margin: 0.625rem auto;
  width: 95%;
}
.breadcrumb p span {
  display: inline-block;
  font-size: 0.75rem;
}
.breadcrumb p span a {
  color: #171717;
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}
.cursos {
  margin-top: 6.0625rem;
}
@media (min-width: 48em) {
  .cursos .content .header {
    margin-bottom: 4rem;
  }
}
.cursos .cursos-list {
  text-align: center;
}
@media (min-width: 64em) {
  .cursos .cursos-list {
    max-width: 71.25rem;
    margin: auto;
    display: block;
  }
}
@media (min-width: 48em) {
  .cursos .cursos-list ul {
    vertical-align: top;
  }
}
.cursos .cursos-list ul li {
  display: inline-block;
  width: 100%;
  margin-bottom: 1.875rem;
}
@media (min-width: 48em) {
  .cursos .cursos-list ul li {
    margin-right: -4px;
    width: 50%;
  }
}
.cursos .cursos-list article {
  text-align: left;
  /* Somente no mobile */
}
@media (max-width: 48em) {
  .cursos .cursos-list article {
    padding: 1rem;
  }
}
.cursos .cursos-list article a {
  text-decoration: none;
  color: #043156;
  align-items: center;
  display: inline-flex;
}
.cursos .cursos-list article img {
  display: inline-block;
  width: 20%;
  width: 80px;
  height: 80px;
  border: #AFAFAF 5px solid;
  border-radius: 50px;
}
@media (min-width: 48em) {
  .cursos .cursos-list article img {
    width: 6.25rem;
    height: 6.25rem;
  }
}
.cursos .cursos-list article h2 {
  display: inline-block;
  width: 80%;
  max-width: 320px;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-left: 0.625rem;
  font-weight: 600;
}
@media (min-width: 48em) {
  .cursos .cursos-list article h2 {
    max-width: 440px;
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
}
.slide-home {
  display: inline-block;
  width: 100%;
  height: 13.25rem;
  overflow: hidden;
}
@media (min-width: 64em) {
  .slide-home {
    height: 21.25rem;
    border-bottom: 25px solid #D8D8D8;
    width: 100%;
  }
}
.owl-pagination-true {
  margin-bottom: 0 !important;
}
.owl-carousel .owl-item img {
  max-width: inherit !important;
}
#slider_81 .owl-dots {
  display: none !important;
}
#slider_81 .sa_hover_container img {
  display: inline-block !important;
  width: 100% !important;
  margin-bottom: 1.875rem;
}
